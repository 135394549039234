import axios from "axios"
import axiosInst from "../libs/axios"

const client_id = "895b79c4-bce4-41e9-95f0-3e07aec28c70"
const client_secret = "4de06063-10af-49f3-93f2-44d0c6759f3d"

type Filters = {
  email?: string
}

export async function getToken(force = false) {
  return new Promise<string>(async (resolve, reject) => {
    try {
      const currentToken = localStorage.getItem("hotmart-token")
      if (currentToken && currentToken.length > 0 && !force) {
        resolve(currentToken)
        return
      }

      const response = await axios.post(
        `https://api-sec-vlc.hotmart.com/security/oauth/token?grant_type=client_credentials`,
        {},
        {
          auth: {
            username: client_id,
            password: client_secret,
          },
        }
      )

      localStorage.setItem("hotmart-token", response.data.access_token)

      resolve(response.data.access_token)
    } catch (e) {
      reject()
    }
  })
}

function get(url: string) {
  const send = async (url: string, token: string) => {
    return axiosInst.post("hotmart/request", {
      url: `https://developers.hotmart.com/payments/api/v1/${url}`,
      token: token,
    })
  }

  return new Promise<any>(async (resolve, reject) => {
    try {
      let token = await getToken()
      let response = await send(url, token)

      if (
        response.data.error &&
        (response.data.error == "token_expired" ||
          response.data.error == "invalid_token")
      ) {
        token = await getToken(true)
        response = await send(url, token)
      }

      resolve(response.data)
    } catch (e) {
      reject(e)
    }
  })
}
export async function getSubscriptions(filters: Filters) {
  return get(
    `subscriptions?max_results=300&${
      filters.email ? `subscriber_email=${filters.email}` : ""
    }`
  )
}

export async function getSales(filters: Filters) {
  return get(
    `sales/history?max_results=300&${
      filters.email ? `buyer_email=${filters.email}` : ""
    }`
  )
}

export async function getSubscriptionDetail(code: String) {
  return get(
    `subscriptions/${code}/purchases`
  )
}