



























































































import {
  BCard,
  BTable,
  BCardBody,
  BCardTitle,
  BCardFooter,
  BCardSubTitle,
  BFormCheckbox,
  BButton,
  BAlert,
  BDropdown,
  BDropdownItem,
  BFormSelect,
} from "bootstrap-vue";
import M from "minimatch";
import store from "@/store/index.js";
import { ref } from "@vue/composition-api";
import swal2 from "sweetalert2";

export default {
  components: {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardFooter,
    BCardSubTitle,
    BFormCheckbox,
    BButton,
    BAlert,
    BDropdown,
    BDropdownItem,
    BFormSelect,
  },
  props: {
    chatsData: {},
    userId: {},
  },
  setup(props: any) {
    const tableColumns = [
      { key: "chat" },
      { key: "joined", label: "Entrou" },
      { key: "revoked", label: "Expirado" },
      { key: "banned", label: "Banido" },
      { key: "link", label: "Link" },
      { key: "action", label: "Ações" },
    ];

    const chatOptions = [
      { value: "-1001513235283", text: "Alunos" },
      { value: "-1001786910448", text: "Alunos Speedway" },
      { value: "-1001523538171", text: "Alunos Maximas" },
      { value: "-1001569325824", text: "Jarvis" },
      { value: "-1001676971024", text: "Blaze" },
      { value: "-1001609745413", text: "Roleta" },
      { value: "-1001727818232", text: "Crash" },
      { value: "-1001732292097", text: "Radar Branco" },
      { value: "-1001386942485", text: "Aviator" },
      { value: "-1001841895335", text: "Cartas" },
      { value: "-1001807117800", text: "Aviator 10x" },
      { value: "-1001512804258", text: "Penalti" },
      { value: "-1001883392464", text: "Mines" },
      { value: "-1001784462454", text: "Over35" },
      { value: "-1001948910835", text: "Double EDS" },
      { value: "-1001804482968", text: "Fortune Rabbit" },
      { value: "-1001768200235", text: "Fortune Tiger" },
      { value: "-1001883926263", text: "Mines 3x" },
    ];

    const selected = "-1001513235283";

    const linksData = ref(props.chatsData ? props.chatsData : []); //? props.chatsData.slice(0, 3) : []

    const deleteLink = (id: number) => {
      store
        .dispatch("app-user/removeLink", { id: id, userId: props.userId })
        .then((res: any) => {
          if (res.data.success) {
            linksData.value.splice(
              linksData.value.findIndex((link: any) => link.id == id),
              1
            );
          }
        })
        .catch(() => {
          swal2.fire({
            title: "Deletar",
            text: "Problema ao deletar o link, verifique se o mesmo está expirado.",
            icon: "error",
          });
        });
    };

    const banLink = (id: number) => {
      store
        .dispatch("app-user/banLink", { id: id, userId: props.userId })
        .then((res: any) => {
          if (res.data.success) {
            const link = linksData.value.find((link: any) => link.id == id);
            if (link) link.banned = true;
          }
        })
        .catch(() => {
          swal2.fire({
            title: "Banir",
            text: "Problema ao banir, verifique se o usuário entrou no grupo ou se já foi banido.",
            icon: "error",
          });
        });
    };

    const revokeLink = (id: number) => {
      store
        .dispatch("app-user/revokeLink", { id: id, userId: props.userId })
        .then((res: any) => {
          if (res.data.success) {
            const link = linksData.value.find((link: any) => link.id == id);
            if (link) link.revoked = true;
          }
        })
        .catch(() => {
          swal2.fire({
            title: "Revogar",
            text: "Problema ao revogar o link, verifique se ele já esta revogado.",
            icon: "error",
          });
        });
    };
    const createLink = (id: string) => {
      store
        .dispatch("app-user/createLink", { id: id, userId: props.userId })
        .then((res: any) => {
          if (res.data.success) {
            linksData.value.push(res.data.chatlink);
          }
        });
    };

    return {
      linksData,
      tableColumns,
      deleteLink,
      banLink,
      revokeLink,
      chatOptions,
      selected,
      createLink,
    };
  },
};
