<template>
  <b-card title="Linha do Tempo">
    <app-timeline>

      <!-- 12 INVOICES HAVE BEEN PAID -->
      <app-timeline-item>
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Se cadastrou no sistema</h6>
          <small class="text-muted">Data?</small>
        </div>
        <p>Esse usuário se cadastrou no sistema da IA Bets</p>
        <!--<p class="mb-0">
          <b-img
            :src="require('@/assets/images/icons/pdf.png')"
            height="auto"
            width="20"
            class="mr-1"
          />
          <span class="align-bottom">invoice.pdf</span>
        </p>-->
      </app-timeline-item>
      <app-timeline-item variant="warning">

        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>Acessou o sistema</h6>
          <small class="text-muted">45 min atrás</small>
        </div>
        <p>Esses valores estão apenas para teste</p>
        <b-media>
          <template #aside>
            <b-avatar :src="require('@/assets/images/avatars/user.png')" />
          </template>
          <h6>Cliente</h6>
          <p class="mb-0">
            Usuário
          </p>
        </b-media>
      </app-timeline-item>
      <app-timeline-item
        variant="info"
        title="Outros updates do Cliente"
        subtitle="Veja mais aqui"
        time="2 days ago"
      />
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BMedia, BAvatar,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
    AppTimeline,
    AppTimelineItem,
  },
}
</script>

<style>

</style>
