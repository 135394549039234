import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Promise } from 'core-js'

export default function useInvoicesList(filters = {}) {
  // Use toast
  const toast = useToast()

  const refInvoiceListTable = ref(null)

  const isLoading = ref(false)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { key: 'product', label: 'Produto', sortable: true },
    { key: 'plan', label: 'Plano', sortable: true },
    { key: 'subscriber_code', label: 'Código', sortable: true },
    { key: 'amount', label: 'Valor', sortable: true, formatter: val => `R$ ${val}` },
    { key: 'status', label: 'Status', sortable: true },
    { key: 'created_at', label: 'Entrou em', sortable: true },
    { key: 'actions' },
  ]

    // Table Handlers
    const tableColumnsPayment = [
      { key: 'id', label: '#', sortable: true },
      { key: 'product', label: 'Produto', sortable: true },
      { key: 'subscriber_code', label: 'Código', sortable: true },
      { key: 'amount', label: 'Valor', sortable: true, formatter: val => `R$ ${val}` },
      { key: 'status', label: 'Status', sortable: true },
      { key: 'created_at', label: 'Pagou em', sortable: true },
      { key: 'actions' },
    ]

  const perPage = ref(10)
  const totalInvoices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    }
  })

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchInvoiceDetail = (code) => {
    return new Promise((resolve, reject) => {
      store
        .dispatch('app-invoice/fetchInvoiceDatail', code)
        .then(response => {
          resolve(response)
        })
        .catch(() => {
          reject()
        });
    })

  }

  const fetchInvoices = (ctx, callback) => {
    isLoading.value = true
    store
      .dispatch('app-invoice/fetchInvoices', filters)
      .then(response => {

        const { items, page_info } = response

        const formattedItems = items.map((item, index) => {
          return {
            id: index + 1,
            plan: item.plan.name,
            product: item.product.name,
            subscriber_code: item.subscriber_code,
            amount: item.price.value,
            status: item.status,
            created_at: (new Date(item.accession_date)).toLocaleString("pt-BR"),
          }
        })

        callback(formattedItems)
        totalInvoices.value = page_info.total_results
        isLoading.value = false
      })
      .catch(() => {
        isLoading.value = false
        toast({
          title: "Erro ao compras da api Hotmart",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        })
      })
  }

  const fetchInvoicesPayments = (ctx, callback) => {
    isLoading.value = true
    store
      .dispatch('app-invoice/fetchInvoicesPayments', filters)
      .then(response => {

        const { items, page_info } = response

        const formattedItems = items.map((item, index) => {
          return {
            id: index + 1,
            product: item.product.name,
            subscriber_code: item.purchase.transaction,
            amount: item.purchase.price.value,
            type: item.purchase.offer.payment_mode,
            status: item.purchase.status,
            created_at: (new Date(item.purchase.order_date)).toLocaleString("pt-BR"),
          }
        }).filter((item) => item.type != 'SUBSCRIPTION')

        callback(formattedItems)
        totalInvoices.value = page_info.total_results
        isLoading.value = false
      })
      .catch(() => {
        isLoading.value = false
        toast({
          title: "Erro ao compras da api Hotmart",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveInvoiceStatusVariantAndIcon = status => {
    if (status === 'ACTIVE') return 'CheckIcon'
    if (status === 'CANCELLED_BY_CUSTOMER') return 'XIcon'
    if (status === 'INACTIVE') return 'XIcon'
    if (status === 'DELAYED') return 'RefreshCcwIcon'
    if (status === 'CANCELLED_BY_ADMIN') return 'XIcon'
    if (status === 'STARTED') return 'ClockIcon'
    if (status === 'CANCELLED_BY_SELLER') return 'XIcon'
    if (status === 'OVERDUE') return 'RefreshCcwIcon'
    return { variant: 'secondary', icon: 'XIcon' }
  }

  const resolveClientStatusVariant = status => {
    if (status === 'ACTIVE') return 'primary'
    if (status === 'CANCELLED_BY_CUSTOMER') return 'danger'
    if (status === 'INACTIVE') return 'secondary'
    if (status === 'DELAYED') return 'warning'
    if (status === 'CANCELLED_BY_ADMIN') return 'danger'
    if (status === 'STARTED') return 'info'
    if (status === 'CANCELLED_BY_SELLER') return 'danger'
    if (status === 'OVERDUE') return 'warning'
    if (status === 'EXPIRED') return 'warning'
    if (status === 'CANCELLED') return 'danger'
    if (status === 'CHARGEBACK') return 'danger'
    if (status === 'NO_FUNDS') return 'danger'
    if (status === 'PROTESTED') return 'danger'
    if (status === 'REFUNDED') return 'danger'
    return 'primary'
  }

  const resolveClientStatus = status => {
    if (status === 'ACTIVE') return 'Ativo'
    if (status === 'CANCELLED_BY_CUSTOMER') return 'Ass. Cancelada'
    if (status === 'INACTIVE') return 'Inativo'
    if (status === 'DELAYED') return 'Atrasada'
    if (status === 'COMPLETE') return 'Completo'
    if (status === 'CANCELLED_BY_ADMIN') return 'Cancelado por Admin'
    if (status === 'STARTED') return 'Aguardando'
    if (status === 'CANCELLED_BY_SELLER') return 'Cancelado'
    if (status === 'OVERDUE') return 'Atrasado'
    return status
  }

  return {
    fetchInvoices,
    fetchInvoicesPayments,
    tableColumns,
    tableColumnsPayment,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInvoiceListTable,
    isLoading,
    statusFilter,

    resolveInvoiceStatusVariantAndIcon,
    resolveClientStatusVariant,
    resolveClientStatus,
    fetchInvoiceDetail,
    refetchData,
  }
}
