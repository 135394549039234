<template>
  <b-card
    no-body
    :class="planData ? 'border-primary' : ''"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        Plano Atual
      </h5>
      <b-badge variant="light-primary">
        {{ planData ? planData.title : 'Sem plano' }}
      </b-badge>
      <small class="text-muted w-100">{{ planData? planData.date : 'Usuário sem plano' }}</small>
    </b-card-header>

    <b-card-body>
      <ul class="list-unstyled my-1">
        <li>
          <span class="align-middle">
            <feather-icon
              icon="RefreshCwIcon"
              class="mr-75"
            />
            <strong>Preço: </strong>
            {{ planData? planData.amount : '-' }}
          </span>
        </li>
        <li class="my-25">
          <span class="align-middle">
            <feather-icon
              icon="CalendarIcon"
              class="mr-75"
            />
            <strong>Próxima cobrança: </strong>
            {{ planData? planData.nextBilling : '-' }}
          </span>
        </li>
        <li>
          <span class="align-middle">
            <feather-icon
              icon="CreditCardIcon"
              class="mr-75"
            />
            <strong>Pagamento: </strong>
            {{ planData? 'Cartão de Crédito' : '-' }}
          </span>
        </li>
      </ul>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        disabled
      >
        {{ planData? 'Alterar Plano' : 'Adicionar um Plano' }}
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
  props: {
    planData: {
    },
  },
}
</script>

<style>

</style>
