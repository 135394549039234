<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="active"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => (active = val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Detalhes da Compra #1</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <div class="p-2" v-if="isLoading">
        <b-skeleton animation="fade" width="50%"></b-skeleton>
        <b-skeleton
          animation="fade"
          width="70%"
          height="25px"
          class="mb-2"
        ></b-skeleton>
        <b-skeleton animation="fade" width="50%"></b-skeleton>
        <b-skeleton
          animation="fade"
          width="70%"
          height="25px"
          class="mb-2"
        ></b-skeleton>
        <b-skeleton animation="fade" width="50%"></b-skeleton>
        <b-skeleton
          animation="fade"
          width="70%"
          height="25px"
          class="mb-2"
        ></b-skeleton>
        <b-skeleton animation="fade" width="50%"></b-skeleton>
        <b-skeleton
          animation="fade"
          width="70%"
          height="25px"
          class="mb-2"
        ></b-skeleton>
        <b-skeleton animation="fade" width="50%"></b-skeleton>
        <b-skeleton
          animation="fade"
          width="70%"
          height="25px"
          class="mb-2"
        ></b-skeleton>
        <b-skeleton animation="fade" width="50%"></b-skeleton>
        <b-skeleton
          animation="fade"
          width="70%"
          height="25px"
          class="mb-3"
        ></b-skeleton>

        <b-skeleton
          animation="fade"
          width="100px"
          height="40px"
          class="mb-2"
        ></b-skeleton>
      </div>

      <b-form class="p-2" v-if="!isLoading">
        <label>Produto</label>
        <b-form-group>
          <b-form-input
            :value="userData.product"
            class="font-weight-bold"
            plaintext
          />
        </b-form-group>

        <label>Status do pagamento</label>
        <div class="d-block">
          <b-badge
            :variant="resolveClientStatusVariant(userData.payment_status)"
            class="mb-1"
          >
            <span>{{ userData.payment_status }}</span>
          </b-badge>
        </div>

        <label>Assinatura</label>
        <div class="d-block">
          <b-badge
            :variant="resolveClientStatusVariant(userData.status)"
            class="mb-1"
          >
            <feather-icon
              :icon="resolveInvoiceStatusVariantAndIcon(userData.status)"
              class="mr-25"
            />
            <span>{{ userData.status }}</span>
          </b-badge>
        </div>

        <label>ID da Transação</label>
        <b-form-group>
          <b-form-input
            :value="userData.transaction_id"
            class="font-weight-bold"
            plaintext
          />
        </b-form-group>

        <label>Data da transação</label>
        <b-form-group>
          <b-form-input
            :value="userData.created_at"
            class="font-weight-bold"
            plaintext
          />
        </b-form-group>


        <label>Tipo de Pagamento</label>
        <b-form-group>
          <b-form-input
            :value="userData.payment_method"
            class="font-weight-bold"
            plaintext
          />
        </b-form-group>

        <label>Valor Pago</label>
        <b-form-group>
          <b-form-input
            :value="'R$ ' + userData.amount"
            class="font-weight-bold"
            plaintext
          />
        </b-form-group>

        <label>Código da compra</label>
        <b-form-group>
          <b-form-input
            :value="userData.subscriber_code"
            class="font-weight-bold"
            plaintext
          />
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Fechar
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BBadge,
  BSkeleton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import useInvoicesList from "./useInvoiceList";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BBadge,
    BSkeleton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isDetailActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isDetailActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    };
  },
  setup(props, { emit }) {
    const blankUserData = {
      id: 0,
      plan: "",
      product: "",
      subscriber_code: "",
      amount: "",
      status: "",
      created_at: 0,
    };

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
    const {
      resolveClientStatus,
      resolveClientStatusVariant,
      resolveInvoiceStatusVariantAndIcon,
      fetchInvoiceDetail,
    } = useInvoicesList();

    const active = ref(false);
    const isLoading = ref(false);

    const viewDetail = async (item) => {
      isLoading.value = true;
      userData.value = item;
      active.value = true;

      fetchInvoiceDetail(item.subscriber_code)
        .then((res) => {
          isLoading.value = false;
          userData.value.payment_method = res[0].payment_method
          userData.value.payment_status = res[0].status
          userData.value.transaction_id = res[0].transaction
        })
        .catch(() => {
          isLoading.value = false;
        });

    };

    return {
      userData,
      active,
      viewDetail,
      isLoading,

      resolveClientStatus,
      resolveClientStatusVariant,
      resolveInvoiceStatusVariantAndIcon
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
